import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/centralVendas',
    component: Layout,
    children: [
        {
            name: 'centralVendas-propostas',
            path: 'propostas',
            component: () => import('@/components/centralVendas/propostas'),
            meta: {
                auth: true
            }
        },
        {
            name: 'centralVendas-propostas-proposta',
            path: 'propostas/proposta',
            component: () => import('@/components/centralVendas/propostas'),
            meta: {
                auth: true
            }
        },
        {
            name: 'centralVendas-propostas-novo',
            path: 'propostas/novo',
            component: () => import('@/components/centralVendas/propostas-novo'),
            meta: {
                auth: true
            }
        },
        {
            name: 'busca-arquivos',
            path: 'buscaArquivosPropostas',
            component: () => import('@/components/centralVendas/buscaArquivosPropostas'),
            meta: {
                auth: true
            }
        },
        {
            name: 'importacao-propostas',
            path: 'importacaoPropostas',
            component: () => import('@/components/centralVendas/importacaoPropostas'),
            meta: {
                auth: true
            }
        },
        {
            name: 'transferencia',
            path: 'transferencia',
            component: () => import('@/components/centralVendas/transferencia'),
            meta: {
                auth: true
            }
        }
    ]
}]
