import Layout from '@/layout/base/layoutSegfy'

export default [{
    path: '/multicalculo',
    component: Layout,
    children: [
        {
            name: 'multicalculo-cotacoes',
            path: 'cotacoes',
            component: () => import('@/components/multicalculo/cotacoes'),
            meta: {
                auth: true
            }
        },
        {
            name: 'multicalculo-novo-orcamento',
            path: 'novo-orcamento',
            component: () => import('@/components/multicalculo/novo-orcamento'),
            meta: {
                auth: true,
                needsPermission: 'isMC'
            }
        },
        {
            name: 'multicalculo-orcamentos',
            path: 'orcamentos',
            component: () => import('@/components/multicalculo/orcamentos'),
            meta: {
                auth: true
            }
        },
        {
            name: 'multicalculo-orcamentos-editar',
            path: 'orcamento-editar',
            component: () => import('@/components/multicalculo/orcamento-editar'),
            meta: {
                auth: true
            }
        },
        {
            name: 'multicalculo-orcamentos-visualizar',
            path: 'orcamento-visualizar',
            component: () => import('@/components/multicalculo/orcamento-visualizar'),
            meta: {
                auth: true
            }
        },
        {
            name: 'multicalculo-orcamentos-recusado',
            path: 'orcamento-recusado',
            component: () => import('@/components/multicalculo/orcamento-recusado'),
            meta: {
                auth: true
            }
        },
        {
            name: 'multicalculo-orcamentos-renovacao',
            path: 'orcamento-renovacao',
            component: () => import('@/components/multicalculo/orcamento-renovacao'),
            meta: {
                auth: true
            }
        },
        {
            name: 'multicalculo-orcamentos-multicalculo',
            path: 'orcamento-multicalculo',
            component: () => import('@/components/multicalculo/orcamento-multicalculo'),
            meta: {
                auth: true,
                needsPermission: 'bloquearOrcamentoVeicular',
                inversePermission: true
            }
        },
        {
            name: 'multicalculo-orcamentos-multicalculo-residencial',
            path: 'orcamento-multicalculo-residencial',
            component: () => import('@/components/multicalculo/orcamento-multicalculo-residencial'),
            meta: {
                auth: true,
                needsPermission: 'bloquearOrcamentoResidencial',
                inversePermission: true
            }
        },
        {
            name: 'multicalculo-orcamentos-multicalculo-condominio',
            path: 'orcamento-multicalculo-condominio',
            component: () => import('@/components/multicalculo/orcamento-multicalculo-condominio'),
            meta: {
                auth: true,
                needsPermission: 'bloquearOrcamentoCondominio',
                inversePermission: true
            }
        },
        {
            name: 'multicalculo-orcamentos-multicalculo-transmitir',
            path: 'orcamento-multicalculo-transmitir',
            component: () => import('@/components/multicalculo/orcamento-multicalculo-transmitir'),
            meta: {
                auth: true,
                needsPermission: 'bloquearOrcamentoTransmitir',
                inversePermission: true
            }
        },
        {
            name: 'multicalculo-orcamentos-multicalculo-pet',
            path: 'orcamento-multicalculo-pet',
            component: () => import('@/components/multicalculo/orcamento-multicalculo-pet'),
            meta: {
                auth: true,
                needsPermission: 'bloquearOrcamentoPet',
                inversePermission: true
            }
        },
        {
            name: 'multicalculo-orcamentos-formulario-auto',
            path: 'formulario-auto',
            component: () => import('@/components/multicalculo/formulario'),
            meta: {
                auth: true
            }
        },
        {
            name: 'multicalculo-novo',
            path: 'orcamento-novo',
            component: () => import('@/components/multicalculo/orcamento-novo'),
            meta: {
                auth: true,
                needsPermission: 'bloquearOrcamentoManual',
                inversePermission: true
            }
        },
        {
            name: 'multicalculo-orcamentos-enviar-email',
            path: 'orcamento-enviar-email',
            component: () => import('@/components/multicalculo/orcamento-enviar-email'),
            meta: {
                auth: true
            }
        },
        {
            name: 'multicalculo-orcamentos-incluir-proposta',
            path: 'orcamento-incluir-proposta',
            component: () => import('@/components/multicalculo/orcamento-incluir-proposta'),
            meta: {
                auth: true
            }
        },
        {
            name: 'multicalculo-orcamento-multicalculo-saude',
            path: 'orcamento-multicalculo-saude',
            component: () => import('@/components/multicalculo/orcamento-multicalculo-saude'),
            meta: {
                auth: true,
                needsPermission: 'bloquearOrcamentoSaude',
                inversePermission: true
            }
        },
        {
            name: 'multicalculo-orcamentos-multicalculo-vida',
            path: 'orcamento-multicalculo-vida',
            component: () => import('@/components/multicalculo/orcamento-multicalculo-vida'),
            meta: {
                auth: true,
                needsPermission: 'bloquearOrcamentoResidencial',
                inversePermission: true
            }
        },
        {
            name: 'multicalculo-orcamentos-multicalculo-empresarial',
            path: 'orcamento-multicalculo-empresarial',
            component: () => import('@/components/multicalculo/orcamento-multicalculo-empresarial'),
            meta: {
                auth: true,
                needsPermission: 'bloquearOrcamentoResidencial',
                inversePermission: true
            }
        },
        {
            name: 'multicalculo-hfy-auto',
            path: 'hfy-auto',
            component: () => import('@/components/multicalculo/hfy-auto'),
            meta: {
                auth: true
            }
        },
        {
            name: 'multicalculo-hfy-comparativo',
            path: 'hfy-comparativo',
            component: () => import('@/components/multicalculo/hfy-comparativo'),
            meta: {
                auth: true
            }
        }
    ]
}]
